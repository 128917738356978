.popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
  }
  
  .popup-inner {
    background: #fff;
    padding: 10px;
    border-radius: 10px;
    width: 300px;
    text-align: center;
    position: relative;
  }
  
  h3 {
    margin-bottom: 10px;
  }
  
  .close-btn {
    background-color: #ff5c5c;
    color: white;
    padding: 5px 10px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    position: absolute;
    top: 10px;
    right: 10px;
  }
  
  .close-btn:hover {
    background-color: #e60000;
  }
  
  .website-btn {
    background-color: transparent;
    border: none;
    cursor: pointer;
    margin-top: 5px;
  }
  
  .website-img {
    width: 80px;
    height: 80px;
  }
  
  .website-btn:hover .website-img {
    opacity: 0.8;
  }
  