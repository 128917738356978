.popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
  }
  
  .popup-inner {
    background: #fff;
    padding: 20px;
    border-radius: 10px;
    max-width: 300px;
    text-align: center;
  }
  
  .input-field {
    width: 80%;
    padding: 10px;
    margin: 5px 0 22px 0;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  
  .btn {
    background-color: #ed0677;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin: 5px;
    width: 80%;
  }
  
  .btn:hover {
    background-color: #c60d69;
  }
  
  .error {
    color: rgb(131, 2, 2);
    font-size: 0.9em;
  }
  